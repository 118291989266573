<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  item_cartel.cartl_name }}</h2>
						<span class="page_subtit ">{{  item_board.board_name }}</span>
					</div>
				</div>
			</div>
			<div class="save mt-10">
				<button
					v-if="is_write"
					@click="$emit('to', { name: 'mafia1271', params: {idx: $route.params.idx, code: $route.params.code, b_id: $route.params.b_id, from: $route.name}})"
					class="btn_save"
				>글쓰기</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-70">
			<div class="container">
				<ul
					v-if="items_bbs.length > 0"
					class="ct_notice_list"
				>
					<li
						v-for="(item, index) in list_bbs"
						:key="'item_' + index"

						@click="$emit('to' , {name: 'mafia058', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: item.bulletin_number}})"
					>
						<a
						>
							<div class="list_tit">{{  item.title }}</div>
						</a>
						<span class="time">{{  $date.getTimeStory(item.registdate) }}</span>
					</li>
				</ul>
				<div
					v-else
					class="intro_content" style="display: flex; flex-direction: column; align-items: center; padding-top: 100%"
				>
					<div class="none_top character3_none">
						<div class="text_guide">
							<span class="cont_none" style="text-align: center; display: block">등록된 {{ item_board.board_name }}이 없습니다</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia127'
		, props:['user']
		, data: function(){
			return {
				program: {
					name: ''
					, title: ''
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
					, from: 'mafia049'
				}
				, items_bbs: []
				, item_board: {
					board_authority: {

					}
				}
				, item_cartel: {}
				, item_board_permission: {}
			}
		}

		,computed: {
			is_write: function(){
				let t = false
				if(this.item_board.board_authority.reg_fg == 'Y'){
					t = true
				}
				return t
			}
			, list_bbs: function (){
				return this.items_bbs.filter(function(item){
					if(item.post_title){
						item.title = item.post_title
					}
					return item
				})
			}
		}
		,methods: {
			getBoardConfig: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})

					if(result.success){
						this.item_board = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBoardPermission: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_board_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})

					if(result.success){
						this.item_board_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getNoticeList: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_notice_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							//, board_number: this.$route.params.b_id
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_bbs = result.data.post_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getCheerList: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_cheer_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_bbs = result.data.post_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBbsList: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_board_bbs_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_bbs = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getCartel: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
		}
		,async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getCartel()
			await this.getBoardConfig()
			if(this.$route.params.code == 'CA00700001') {
				await this.getNoticeList()
			}else if(this.$route.params.code == 'CA00700002'){
				await this.getCheerList()
			}else{
				await this.getBbsList()
			}

			// await this.getBoardPermission()

		}
	}
</script>